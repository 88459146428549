<template>
    <div class="w100 bs">
        <div class="w100 bgc_fff" style="border-radius: 2px;min-height: calc(100vh - 360px);">
            <div class="bs w100 pos-tabs-box flex align-center justify-between">
                <el-tabs v-model="activeName" class="pos-tabs bs shrink_0" @tab-click="handleClick">
                    <el-tab-pane label="个人信息" name="first"></el-tab-pane>
                    <el-tab-pane label="企业信息" name="two"></el-tab-pane>
                </el-tabs>

            </div>
            <div class="aC-tbox w100 bs c_262626 text-14" v-show="activeName == 'first'">
                <div class="text-16 aC-user">个人信息</div>
                <div class="flex aC-user-tr">
                    <div class="aC-user-tr-left">联系人</div>
                    <div class="flex" v-show="!editContacts">
                        <div>{{ contacts }}</div>
                        <div style="margin-left:16px;" class="c_3291F8 cur-p" @click="editContacts = true">修改</div>
                    </div>
                    <div class="flex" v-show="editContacts">
                        <el-input v-model="contacts" placeholder="请输入联系人" />
                        <div style="margin-left:16px;width:80px;" @click="contactsComit" class="c_3291F8 cur-p">保存修改
                        </div>
                    </div>
                </div>
                <div class="flex aC-user-tr">
                    <div class="aC-user-tr-left">简历接收邮箱</div>
                    <div class="flex" v-show="!editMailbox">
                        <div>{{ mailbox || '--' }}</div>
                        <div style="margin-left:16px;" class="c_3291F8 cur-p" @click="editMailbox = true">修改</div>
                    </div>
                    <div class="flex" v-show="editMailbox">
                        <el-input v-model="mailbox" placeholder="" />
                        <div style="margin-left:16px;width:80px;" @click="mailboxComit" class="c_3291F8 cur-p">保存修改
                        </div>
                    </div>
                </div>
                <!-- <div class="flex aC-user-tr">
                    <div class="aC-user-tr-left">微信绑定</div>
                    <div class="flex">
                        <div>wx464646</div>
                        <div style="margin-left:16px;" class="c_3291F8">绑定</div>
                    </div>
                </div> -->
                <div class="flex aC-user-tr">
                    <div class="aC-user-tr-left">手机号</div>
                    <div class="flex" v-show="!editMobile">
                        <div>{{ mobile }}</div>
                        <!-- <div style="margin-left:16px;" class="c_3291F8 cur-p" @click="editMobile = true">修改</div> -->
                    </div>
                    <div class="flex" v-show="editMobile">
                        <!-- <el-input v-model="pwd1" placeholder="" /> -->
                        <div style="margin-left:16px;width:80px;" class="c_3291F8 cur-p">保存修改
                        </div>
                    </div>
                </div>
                <div class="flex aC-user-tr">
                    <div class="aC-user-tr-left">密码</div>
                    <div class="flex align-center" v-show="!editPwd">
                        <div>****</div>
                        <div style="margin-left:16px;" class="c_3291F8 cur-p" @click="editPwd = true">修改</div>
                    </div>
                    <div class="flex" v-show="editPwd">
                        <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules" label-width="80px"
                            class="demo-ruleForm" style="width:360px;">
                            <el-form-item label="密码" prop="pwd1">
                                <el-input v-model="ruleForm.pwd1" type="password" autocomplete="off" />
                            </el-form-item>
                            <el-form-item label="确认密码" prop="pwd2">
                                <el-input v-model="ruleForm.pwd2" type="password" autocomplete="off" />
                            </el-form-item>
                        </el-form>
                        <div style="margin-left:16px;width:80px;" class="c_3291F8 cur-p" @click="pwdComit">保存修改
                        </div>
                    </div>
                </div>
            </div>
            <div class="aC-tbox w100 bs" v-show="activeName == 'two'">
                <div class="flex justify-between">
                    <div class="flex">
                        <img class="aC-logoW" :src="userInfo.photo" alt="">
                        <div class="aC-nameW">
                            <div class="text-18" style="font-weight: bold;line-height: 36px;">{{ companyInfo.name }}
                            </div>
                            <p class="text-14 c_eb" style="line-height: 24px;">公司简称：{{ companyInfo.simpleName }}</p>
                            <p class="text-14 c_eb" style="line-height: 24px;">公司介绍：{{companyInfo.description}}</p>
                        </div>
                    </div>
                    <div>
                        <!-- <el-button type="primary" @click="goCom">编辑</el-button> -->
                    </div>
                </div>
                <ul class="flex w100">
                    <li class="aC-company-li text-14 c_262626 flex align-center justify-center"
                        v-for="(item, index) in companyInfo.list" :key="index">{{ item }}</li>
                </ul>
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { updateUser, getUserInfo, listDict } from '@/util/apis'
import { defineComponent } from 'vue';
import router from "@/router/index";
import { ElLoading, ElMessage } from 'element-plus'
export default defineComponent({
    name: "Home",
    data() {
        return {
            activeName: 'first',
            contacts: '',
            editContacts: false,
            mailbox: '',
            editMailbox: false,
            mobile: "",
            editMobile: false,
            pwd: '****',
            editPwd: false,
            ruleForm: {
                pwd1: '',
                pwd2: '',
            },
            rules: {
                pwd1: [{ validator: this.validator1, trigger: 'blur', }],
                pwd2: [{ validator: this.validatePass2, trigger: 'blur' }]
            },
            companyInfo: {} as any,
            userInfo: {} as any,

        }
    },
    mounted() {
        var doArr = ['company_nature', 'company_scale', 'company_stage', 'company_industry'].map(item => listDict({ type: item }));
        var gUserInfo = {} as any;
        Promise.all([...doArr]).then(resList => {
            console.log('resList==', resList)
            //公司性质、公司规模、发展阶段、公司行业
            this.gUserInfo(resList);
        })

    },
    methods: {
        goCom(){
            router.push({ path: "/companyInfo" });
        },
        validator1(rule: any, value: any, callback: any) {
            if (value === '') {
                callback(new Error('密码不能为空！'))
            } else {
                // if (this.ruleForm.pwd2 !== '') {
                //     if (!(this.$refs.ruleFormRef as any).value) return
                //     (this.$refs.ruleFormRef as any).value.validateField('pwd2', () => null)
                // }
                var str = RegExp("^[0-9a-zA-Z]*$")
                if (!str.test(value) || value.length < 6 || value.length > 20) {
                    callback(new Error('当前密码格式不正确!不少于6位且不多于20位长度'))
                } else {
                    callback()
                }
            }

        },
        validatePass2(rule: any, value: any, callback: any) {
            if (value === '') {
                callback(new Error('密码不能为空！'));
            } else if (value !== this.ruleForm.pwd1) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback()
            }
        },
        handleClick(tab: any, event: Event) {
            console.log(tab, event)
        },
        gUserInfo(resList: any) {
            getUserInfo({}).then(res => {
                if (res.code == 0) {
                    this.contacts = res.data.userInfo.name;
                    this.mailbox = res.data.userInfo.email || '';
                    this.mobile = res.data.userInfo.mobile || '';
                    this.userInfo = res.data.userInfo;
                    if (res.data.companyInfo) {
                        res.data.companyInfo.list = [resList[0].data.find((item: any) => item.id == res.data.companyInfo.nature)?.name || '', resList[1].data.find((item: any) => item.id == res.data.companyInfo.scale)?.name || '', resList[2].data.find((item: any) => item.id == res.data.companyInfo.stage)?.name || '', resList[3].data.find((item: any) => item.id == res.data.companyInfo.industry)?.name || '']
                        this.companyInfo = res.data.companyInfo;
                    }
                }
            })
        },
        contactsComit() {
            this.editContacts = false;
            this.updateUserFunction({
                name: this.contacts
            })
        },
        mailboxComit() {
            this.editMailbox = false;
            this.updateUserFunction({
                email: this.mailbox
            })
        },
        async pwdComit() {
            await (this.$refs.ruleFormRef as any).validate((valid: any, fields: any) => {
                if (valid) {
                    this.editPwd = false;
                    this.updateUserFunction({
                        password: this.ruleForm.pwd1
                    })
                }
            })
        },
        updateUserFunction(params: any) {
            updateUser(params).then(res => {
                if (res.code == 0) {
                    ElMessage({
                        showClose: true,
                        message: '保存成功',
                        type: 'success',
                    })
                }
            })
        }
    },
    setup: () => {

    }
})
</script>
<style scoped>
@import "./index.css";
</style>