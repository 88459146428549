
import { updateUser, getUserInfo, listDict } from '@/util/apis'
import { defineComponent } from 'vue';
import router from "@/router/index";
import { ElLoading, ElMessage } from 'element-plus'
export default defineComponent({
    name: "Home",
    data() {
        return {
            activeName: 'first',
            contacts: '',
            editContacts: false,
            mailbox: '',
            editMailbox: false,
            mobile: "",
            editMobile: false,
            pwd: '****',
            editPwd: false,
            ruleForm: {
                pwd1: '',
                pwd2: '',
            },
            rules: {
                pwd1: [{ validator: this.validator1, trigger: 'blur', }],
                pwd2: [{ validator: this.validatePass2, trigger: 'blur' }]
            },
            companyInfo: {} as any,
            userInfo: {} as any,

        }
    },
    mounted() {
        var doArr = ['company_nature', 'company_scale', 'company_stage', 'company_industry'].map(item => listDict({ type: item }));
        var gUserInfo = {} as any;
        Promise.all([...doArr]).then(resList => {
            console.log('resList==', resList)
            //公司性质、公司规模、发展阶段、公司行业
            this.gUserInfo(resList);
        })

    },
    methods: {
        goCom(){
            router.push({ path: "/companyInfo" });
        },
        validator1(rule: any, value: any, callback: any) {
            if (value === '') {
                callback(new Error('密码不能为空！'))
            } else {
                // if (this.ruleForm.pwd2 !== '') {
                //     if (!(this.$refs.ruleFormRef as any).value) return
                //     (this.$refs.ruleFormRef as any).value.validateField('pwd2', () => null)
                // }
                var str = RegExp("^[0-9a-zA-Z]*$")
                if (!str.test(value) || value.length < 6 || value.length > 20) {
                    callback(new Error('当前密码格式不正确!不少于6位且不多于20位长度'))
                } else {
                    callback()
                }
            }

        },
        validatePass2(rule: any, value: any, callback: any) {
            if (value === '') {
                callback(new Error('密码不能为空！'));
            } else if (value !== this.ruleForm.pwd1) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback()
            }
        },
        handleClick(tab: any, event: Event) {
            console.log(tab, event)
        },
        gUserInfo(resList: any) {
            getUserInfo({}).then(res => {
                if (res.code == 0) {
                    this.contacts = res.data.userInfo.name;
                    this.mailbox = res.data.userInfo.email || '';
                    this.mobile = res.data.userInfo.mobile || '';
                    this.userInfo = res.data.userInfo;
                    if (res.data.companyInfo) {
                        res.data.companyInfo.list = [resList[0].data.find((item: any) => item.id == res.data.companyInfo.nature)?.name || '', resList[1].data.find((item: any) => item.id == res.data.companyInfo.scale)?.name || '', resList[2].data.find((item: any) => item.id == res.data.companyInfo.stage)?.name || '', resList[3].data.find((item: any) => item.id == res.data.companyInfo.industry)?.name || '']
                        this.companyInfo = res.data.companyInfo;
                    }
                }
            })
        },
        contactsComit() {
            this.editContacts = false;
            this.updateUserFunction({
                name: this.contacts
            })
        },
        mailboxComit() {
            this.editMailbox = false;
            this.updateUserFunction({
                email: this.mailbox
            })
        },
        async pwdComit() {
            await (this.$refs.ruleFormRef as any).validate((valid: any, fields: any) => {
                if (valid) {
                    this.editPwd = false;
                    this.updateUserFunction({
                        password: this.ruleForm.pwd1
                    })
                }
            })
        },
        updateUserFunction(params: any) {
            updateUser(params).then(res => {
                if (res.code == 0) {
                    ElMessage({
                        showClose: true,
                        message: '保存成功',
                        type: 'success',
                    })
                }
            })
        }
    },
    setup: () => {

    }
})
